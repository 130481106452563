import { token } from "../../utils/authentication/Auth";

export function getParams(
    type: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "GETREFRESH" | "POSTLOGIN" | "POSTFILE",
    body?: string,
    contentType?: string | undefined,
) {
    const content = contentType !== undefined ? contentType : "application/json; charset=utf-8";
    let params: RequestInit = {};
    switch (type) {
        case "POSTLOGIN":
            params = {
                body,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                method: "POST",
            };
            break;
        case "GETREFRESH":
            params = {
                credentials: "include",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                method: "GET",
            };
            break;
        case "GET":
            params = {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + token,
                },
                method: "GET",
            };
            break;
        case "POST":
                params = {
                    body,
                    headers: {
                        "Content-Type": content,
                        "Authorization": "Bearer " + token,
                    },
                    method: "POST",
                };
                break;
        case "POSTFILE":
            params = {
                body,
                headers: {
                    // Content-Type must not be set manually here, because the multipart/form
                    // content type gets set automatically (with extra necessary information)
                    Authorization: "Bearer " + token,
                },
                method: "POST",
            };
            break;
        case "PUT":
            params = {
                body,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + token,
                },
                method: "PUT",
            };
            break;
        case "DELETE":
            params = {
                body,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + token,
                },
                method: "DELETE",
            };
            break;
        case "PATCH":
            params = {
                body,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + token,
                },
                method: "PATCH",
            };
            break;
    }
    return params;
}
