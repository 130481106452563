import { getParams } from "./Params";
import {redirectToLogin} from "./../../utils/authentication/Auth";

// Sjekker status på responsen
export const checkStatus = (response: any) => {
    if (response.ok) {
        return response;
    } else {
        if(response.status === 401) {
            redirectToLogin()
        }
        const error: any = new Error(response.statusText);
        error.errorResponse = response;
        throw error;
    }
};

// Gjør om responsen til JSON
export const parseJSON = (result: any) => {
    return result.json();
};

// Hjelpefunksjoner for get, put, post, patch og delete. Laget egne funksjoner for refresh og login.
export const Fetcher = {
    postLogin: (path: string, body: any) =>
        fetch(path, getParams("POSTLOGIN", body))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),

    getRefresh: (path: string) =>
        fetch(path, getParams("GETREFRESH"))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),

    get: (path: string) =>
        fetch(path, getParams("GET"))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),

    getWithoutJSON: (path: string) =>
        fetch(path, getParams("GET"))
        .then(checkStatus)
        .then((result) => result)
        .catch((error) => error),

    post: (path: string, body: any, contentType?: string) =>
        fetch(path, getParams("POST", body, contentType))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),
    postFile: (path: string, body: any) =>
        fetch(path, getParams("POSTFILE", body))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),
    put: (path: string, body: any) =>
        fetch(path, getParams("PUT", body))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),

    patch: (path: string, body: any) =>
        fetch(path, getParams("PATCH", body))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),

    delete: (path: string, body?: string) =>
        fetch(path, getParams("DELETE", body))
        .then(checkStatus)
        .then(parseJSON)
        .then((result) => result)
        .catch((error) => error),
};
