import * as Remote from "../../model/remote/RemoteAuth";

export let token: string | undefined = "";
export let exp: any;
export const getAuthToken = () => token !== undefined ? token : undefined;
export const isAuthenticated = () => !!getAuthToken();

export const refresh = async () => {
    const response = await Remote.refresh();
    const { authToken, expires } = response;
    setAuth(authToken, expires);
    return response;
};

export const setAuth = (Atoken: string, expires: string) => {
    token = Atoken;
    exp = expires;
};

export const logOut = async () => {
    await Remote.logout();
    redirectToLogin();
};

export const redirectToLogin = async () => {
    token = "";
    setAuth("", "");
};