import {Fetcher} from "../utils/Fetcher";
const varsSource = window["appValues"] ? window["appValues"] : process.env;

export async function login(body: string) {
    console.log({varsSource})
    const response = await Fetcher.postLogin(
        varsSource.REACT_APP_ENDPOINT
        + "/auth",
        body,
        );
    return response;
}

export async function refresh() {
    const response = await Fetcher.getRefresh(
        varsSource.REACT_APP_ENDPOINT
        + "/auth/refresh");
    return response;
}

export async function logout() {
    const response = await Fetcher.getRefresh(
        varsSource.REACT_APP_ENDPOINT
        + "/auth/logout");
    return response;
}
