import { createTheme as createThemeV5, ThemeOptions } from "@mui/material/styles";

export const themeOptions = {
    palette: {
        primary: {
            light: "#ff9741",
            main: "#ec6607",
        },
        secondary: {
            main: "#323232",
            dark: "#262626",
            light: "#5b5b5b",
            contrastText: "#ffffff",
        },
        error: {
            light: "#ef5350",
            main: "#d32f2f",
            dark: "#c62828",
        },
        background: {
            default: "#eeeeee",
            paper: "#ffffff"
        },
        text: {
            primary: "#262626",
            secondary: "#262626",
            disabled: "#bdbdbd",
        },
        light: {
            main: "#ffffff",
            contrastText: "#262626"
        },
        dark: {
            main: "#262626",
            contrastText: "#ffffff",
        },
        icons: {
            success: "#008756",
            warning: "#ff9800",
            error: "#f44336",
            info: "#2196f3",
            disabled: "#d3d3d3",
        },
        scrollbar: {
            thumb: {
                dark: "#bdbdbd",
                light: "#ffffff",
            },
            track: {
                dark: "#dddddd",
                light: "#eeeeee",
            },
            thumbHover: {
                dark: "#323232",
                light: "#2b2b2b",
            }
        },
        success: {
            main: "#2e7d32",
            dark: "#1b5e20",
            light: "#4caf50",
        }
    },
    spacing: 3,
}

const inputPadding = {
    padding: "10px 14px 9px",
}

export const baseFontSize = 14;

const themeOptionsV5: ThemeOptions = {
    palette: {
        ...themeOptions.palette,
    }
}

const spacing = themeOptions.spacing * 8;
const doubleSpacing = spacing * 2;

const mainThemeV5 = createThemeV5({
    ...themeOptionsV5,
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    padding: 0,
                    "& .MuiOutlinedInput-root": {
                        padding: 0,
                        "& .MuiAutocomplete-input": {
                            ...inputPadding,
                        }
                    }
                },
                option: {
                    background: "#ffffff",
                },
                popper: {
                    background: "#ffffff",
                },
                input: {
                    borderBottom: "none !important",
                    height: "auto",
                },
                endAdornment: {
                    top: "auto",
                }
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    border: "inherit",
                    "&.MuiButton-containedPrimary": {
                        backgroundColor: themeOptions.palette.primary.main,
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    "&.selected": {
                        border: "1px solid " + themeOptions.palette?.success?.main,
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& .bg--gray": {
                        background: themeOptions.palette.background.default,
                        "&.MuiGrid-container": {
                            width: `calc(100% + ${doubleSpacing}px)`,
                            padding: spacing,
                            paddingLeft: 0,
                            marginRight: -spacing,
                        },
                    },
                    // Styling for gray content in catalog dialog
                    "& .fullWidth": {
                        background: themeOptions.palette.background.default,
                        "&.MuiGrid-container": {
                            width: `calc(100% + ${doubleSpacing*1.375}px)`, // (100% + 66px)
                            marginLeft: -spacing*1.375, // (-33px)
                        },
                    },
                    "& .MuiGrid-item": {
                        "& .MuiTypography-h3": {
                            marginTop: spacing,
                        }
                    }
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "20px 24px",
                    paddingTop: 0,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 20,
                    overflowX: "hidden",
                    "*::-webkit-scrollbar": {
                        width: "0.7em",
                    },
                    "*::-webkit-scrollbar-track": {
                        backgroundColor: themeOptions?.palette?.scrollbar?.track?.light,
                    },
                    "*::-webkit-scrollbar-thumb": {
                        backgroundColor: themeOptions?.palette?.scrollbar.thumb.dark,
                        border: `0.1em solid ${themeOptions?.palette?.scrollbar.thumb.dark}`,
                        borderRadius: "0.5em",
                    },
                    "*::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: `${themeOptions?.palette?.scrollbar?.thumbHover?.light}`,
                    }
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    "& .MuiBox-root": {
                        display: "flex",
                        alignItems: "center",
                        "& > .MuiSvgIcon-root": {
                            marginRight: 10,
                        }
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        border: 0,
                    },
                    minWidth: 100,
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: baseFontSize,
                    height: "auto",
                    padding: 0,
                    background: "#ffffff",
                },
                input: {
                    height: "auto !important",
                    borderBottom: "0 !important",
                    ...inputPadding,
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: baseFontSize,
                    background: "transparent",
                    "&.displayBlank": {
                        left: -5,
                        padding: "0 7px",
                        "&::after": {
                            content: "''",
                            display: "block",
                            background: "white",
                            width: "100%",
                            height: 10,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            zIndex: -1,
                        },
                    },
                },
                shrink: {
                    top: 1,
                }
            },
        },
        // MuiLoadingButton: {
        //     styleOverrides: {
        //         root: {
        //             "&.MuiButton-containedPrimary.Mui-disabled": {
        //                 backgroundColor: themeOptions.palette.primary.main,
        //                 opacity: 0.6,
        //             }
        //         }
        //     }
        // },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    background: "#ffffff",
                    fontSize: baseFontSize,
                    borderRadius: 0,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "inherit",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: baseFontSize,
                    background: "#ffffff",
                },
                input: {
                    ...inputPadding,
                    borderBottom: "0 !important",
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    minWidth: 100,
                    background: "#ffffff",
                    "& .placeholder": {
                        color: "#bdbdbd",
                    },
                },
            },
        },
        // MuiTreeItem: {
        //     styleOverrides: {
        //         label: {
        //             fontSize: 12,
        //         }
        //     }
        // },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.MuiListItemText-primary": {
                        lineHeight: "inherit",
                    },
                },
            },
            variants: [
                {
                    props: {
                        variant: "h3",
                    },
                    style: {
                        fontSize: "1.25rem",
                    }
                }
            ]
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "& .MuiRadio-root": {
                        "& span": {
                            "& .MuiSvgIcon-root": {
                                // Color for the radio button circle.
                                color: "#757575", // TODO Change to themeOptions.palette.something
                            }
                        }
                    },
                    "& .Mui-checked": {
                        "& span": {
                            "& .MuiSvgIcon-root": {
                                // Color for the radio button circle & dot when selected.
                                color: themeOptions.palette.primary.main,
                            }
                        }
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    margin: 0,
                },
                actions: {
                    minWidth: 120,
                    display: "inline-flex",
                    justifyContent: "flex-end",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    height: 53, // Specified to prevent "jumping" when editing.
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: themeOptions.palette.text.primary,
                    "&.Mui-selected": {
                        color: themeOptions.palette.text.primary,
                        "&.deleted-checklists": {
                            color: themeOptions.palette.error.light,
                        },
                        "&.active-checklists": {
                            color: themeOptions.palette.primary.main,
                        }
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    "& .MuiTabs-scroller": {
                        "& .MuiTabs-indicator": {
                            backgroundColor: themeOptions.palette.secondary.dark,
                            "&.deleted-checklists": {
                                backgroundColor: themeOptions.palette.error.light,
                            },
                            "&.active-checklists": {
                                backgroundColor: themeOptions.palette.primary.main,
                            },
                        },
                    },
                },
            },
        },
    },
});

export {
    mainThemeV5,
};