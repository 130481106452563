import React, { useState } from "react";
import * as Remote from "../../model/remote/RemoteAuth";
import { refresh, setAuth } from "../../utils/authentication/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import {getStatus} from "../../utils/error/Status"
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./styles/login.css"
import { Loading } from "../../components/loading/loading";


export default function LoginPage({
    setLoggedIn
}:{
    setLoggedIn: any,
}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ submitted, setSubmitted ] = useState(false);
    const [ waitForRefresh, setWaitForRefresh ] = useState<boolean>(true);
    const [ stateLoggedIn, setStateLoggedIn] = useState<boolean>(false);
    let navigate = useNavigate();
    let location = useLocation();

    const redirect = () => {
        // redirect to the correct page. 
        if (location.state !== undefined && location.state !== null) {
            const state = location.state;
            if (typeof state === "object") {
                const stateCopy: any = {...state}
                const pathname = stateCopy?.pathname ?? "";
                const searchstring = stateCopy?.search ?? "";
                navigate(pathname + searchstring);
            }
        } else {
            navigate("/");
        }
    };

    React.useEffect(() => {
        const checkRefresh = async () => {
            const response = await refresh();
            if (response.authToken !== undefined) {
                setStateLoggedIn(true);
            } else {
                setWaitForRefresh(false);
            }
        };
        checkRefresh();
    }, []);

    React.useEffect(() => {
        const checkRefresh = () => {
            if(stateLoggedIn) {
                setLoggedIn();
                redirect();
            }
        };
        checkRefresh();
    });


    const action = (key: any) => {
        return (
            <IconButton onClick={() => {closeSnackbar(key)}} aria-label="Lukk" color="inherit" size="small">
                <CloseIcon fontSize="small"/>
            </IconButton>
        )
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitted(true);
        if (username && password) {
            // Request to log in user
            const body = JSON.stringify({username, password});
            console.log({body})
            const response = await Remote.login(body);
            console.log({response})

            // handle errormessage or set state to logged in
            if (response.errorResponse !== undefined) {

                // It's possible to customize errormessages here. 
                const errorText: string = response.errorResponse.statusText !== "" ? response.errorResponse.statusText : getStatus(response);
                const errorCode: number = response.errorResponse.status;
                enqueueSnackbar(errorCode + " - " + errorText, {variant: "error", persist: true, action});

            } else {
                const { authToken, expires } = response;
                setAuth(authToken, expires);
                setLoggedIn(true);
                redirect();
            }
        }
    };



    return (
        <>
        { waitForRefresh ?
            <Loading/>
        : 
            <div className="login">
                <div className="layer">
                    <img src={"/img/Logo_vertical.png"} alt="Cordel_logo"/>
                    <div className="login-container">
                        <div className="login-content">
                            <h1>Logg inn</h1>
                            <form onSubmit={handleSubmit} className="bg--white">
                                <label>
                                    Brukernavn
                                    {submitted && !username && <span className="input-error" id="login-InputErrorUsername">Skriv inn brukernavn</span>}
                                    <input
                                        type="text"
                                        name="userName"
                                        value={username}
                                        className={submitted && !username ? "has-error" : ""}
                                        onChange={(e) => setUsername(e.target.value)}
                                        autoComplete="on"
                                    />
                                </label>
                                <label>
                                    Passord
                                    {submitted && !password && <span className="input-error" id="login-InputErrorUserPassword">Skriv inn passord</span>}
                                    <input
                                        type="password"
                                        name="userPassword"
                                        value={password}
                                        className={submitted && !password ? "has-error" : ""}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="on"
                                    />
                                </label>
                                <input type="submit" name="loginButton" value="Logg inn"/>
                                {/* {error && password && username && <span className="error-alert" id="login-LoginError">{error}</span>} */}
                            </form>    
                        </div>
                    </div><p className="version">3.0.0</p>
                </div>
            </div>
        }
        </>
    );
}
