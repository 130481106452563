import React from "react";
import "./styles/404.css"

function NotFound () {
    return (
        <div className="notFound">
            <span>404</span>
            <h1>Ups! Beklager vi fant ikke siden.</h1>
            <p>Lukk vinduet og prøv igjen.</p>
        </div>
    )
}


export default NotFound;
