import * as React from "react";
import "./styles/loading.css";

export const Loading = () => {
    return (
        <>
            <div className="loader-wrapper">
                <div className="loader"></div>
            </div>
        </>
    );
};