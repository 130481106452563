import React, {useEffect, useState} from "react";
import {
  Routes,
  Route, 
  Navigate,
  useLocation
} from "react-router-dom";
import LoginPage from "../login/LoginPage";
import {isAuthenticated, refresh} from "../../utils/authentication/Auth";
import NotFound from "./../views/errorViews/NotFound404";
import HealthStatus from "./../views/health";
import { Loading } from "../../components/loading/loading";

const PreviewChecklist = React.lazy(() => import("../views/checklist/PreviewCheckList"));

export default function RoutesComponent() {

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const timer = setInterval(async () => {
            if (isLoggedIn) {
                const response = await refresh();
                if (response.errorResponse !== undefined && response.errorResponse["status"] === 401) {
                    setIsLoggedIn(false);
                }
            }
        }, 300000);
        return () => clearInterval(timer);
    })

    return(
        <Routes>
            <Route path="login" element={
                <LoginPage setLoggedIn={isLoggedIn === false ? () => setIsLoggedIn(true) : () => {}}/>
            }/>
            <Route path="health" element={<HealthStatus />} />
            <Route path="sjekklister/*" element={
                <AuthRoute>
                    <PreviewChecklist/>
                </AuthRoute>
            }/>
            <Route path="/*" element={<AuthRoute><NotFound/></AuthRoute>} />
            <Route path="/" element={<NotFound/>} />
        </Routes>
    );
}

function AuthRoute({children}: any) {
    const location = useLocation();
    const hasAuth = isAuthenticated();

    // If not logged in, navigate to login screen
    if (!hasAuth) { return <Navigate replace to="/login" state={location} /> }

    return (
        <React.Suspense fallback={<Loading/>}>
            {children}
        </React.Suspense>
    );
}