import { logOut } from "../authentication/Auth";

export const getStatus = (error: any) => {
    const { errorResponse } = error;
    if (errorResponse.status === 401) {
        logOut();
        return "Innloggingsinformasjon ikke gyldig.";
    } else if (errorResponse.status === 400) {
        return "Bad request";
    } else if (errorResponse.status === 500) {
        return "Internal server error";
    }
    return;
};

const getStatusText = (response: any) => {
    if (response.errorResponse.statusText !== "")
        return response.errorResponse.statusText;
    return getStatus(response);
};

/** Function that creates a notification if the response fails.
 * @param response the response from the fetch call
 * @param snackbar function to display error message.
 * @param errorMessage optional - custom message for error
 * @param setIsLoading optional - setState boolean function - can be used to stop loading
 */
export const handleErrorResponse = (
    response: any,
    snackbar: Function,
    errorMessage?: string,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setIsLoading?.(false);
    if (response?.errorResponse === undefined) {
        return;
    }
    const errorText: string = errorMessage ?? getStatusText(response);
    snackbar(errorText, { variant: "error" });
};
