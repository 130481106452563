import React from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";
import Routes from "./views/routes/Routes";
import { mainThemeV5 } from "./themes/main/theme";
import { ThemeProvider } from "@mui/material/styles";

function App() {
    return (
    <>
        <ThemeProvider theme={mainThemeV5}>
            <Router>
                <Routes/>
            </Router>
        </ThemeProvider>
    </>
    );
}

export default App;
